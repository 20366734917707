/* TODO: styles are a big mess. somethings are located here that should be part of tekis.components.css and the other way around */

/* tekis color: 3eb1c8. tekis light: 8bcfdd */

/* Container */

.container-fluid {
   max-width: 1024px;
   margin-left: 0;
}

.navbar .container-fluid {
    max-width: none;
}

/* Modals */

@media screen and (min-width: 768px) {
    .modal-md {
        width: 360px;
    }
}

@media screen and (min-width: 768px) {
    .modal-lgr {
        width: auto;
        margin: 10px;
    }
}

.modal-header-tekis {
    border-radius: 5px 5px 0 0;
    background-color: #3eb1c8;
    border-bottom: none;
}

.modal-header-tekis .modal-title {
    color: white;
}

.modal-content .alert {
    border-radius: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 0;
}

/* Notifications */

.notification {
    position: fixed;
    top: 4px;
    right: 4px;
    min-width: 33%;
    max-width: 33%;
    z-index: 10000;
}

@media screen and (max-width: 979px) and (min-width: 768px) {
    .notification {
        /*top: 53px;
        right: 3px;*/
        max-width: 50%;
    }
}

@media screen and (max-width: 767px) {
    .notification {
        max-width: 66%;
    }
}

.notification .alert {
    text-align: center;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 4px;
    box-shadow: 0 5px 13px rgba(0,0,0,.4);
}

/* Bootstrap navbar */

.navbar-tekis {
    background-color: #3eb1c8;
    border: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.navbar-tekis.navbar-default .navbar-nav > li > a {
    color: white;
    padding-left: 10px;
    padding-right: 10px;
    transition: background-color 0.3s ease;
}

.navbar-tekis.navbar-default .navbar-text {
    margin: 0;
    padding: 15px 10px;
    color: white;
}

.navbar-tekis.navbar-default .navbar-nav > li > a:hover,
.navbar-tekis.navbar-default .navbar-nav > .active > a:hover {
    color: white;
    background-color: #8bcfdd;
}

.navbar-tekis.navbar-default .navbar-text,
.navbar-tekis.navbar-default .navbar-nav > li > a:focus,
.navbar-tekis.navbar-default .navbar-nav > .active > a,
.navbar-tekis.navbar-default .navbar-nav > .active > a:focus {
    color: white;
}

.navbar-tekis.navbar-default .navbar-nav > .active > a,
.navbar-tekis.navbar-default .navbar-nav > .active > a:focus {
    background-color: #8bcfdd;
}

.navbar-tekis.navbar li.dropdown ul.dropdown-menu {
    padding-top: 0;
}

.navbar-tekis.navbar li.dropdown ul.dropdown-menu .divider {
    margin: 5px 0;
}

.navbar-tekis.navbar li.dropdown ul.dropdown-menu .dropdown-header {
    padding: 0;
    font-size: 100%;
}
.navbar-tekis.navbar li.dropdown ul.dropdown-menu .dropdown-header > div {
    background-color: #eee;
    color: #555;
    padding: 12px 25px 11px 15px;
}

.navbar-tekis.navbar li.dropdown ul.dropdown-menu > li > a {
    padding: 12px 15px;
    transition: background-color 0.3s ease;
}

.navbar-tekis.navbar-default .navbar-nav > li.disabled > a,
.navbar-tekis.navbar li.dropdown ul.dropdown-menu > li.disabled > a {
    opacity: 0.5;
}

.navbar-tekis ul.navbar-nav > li > a > span + span.glyphicon {
    padding-left: 6px;
}

.navbar-tekis.navbar li.dropdown ul.dropdown-menu > li > a > div {
    padding-right: 28px;
}
.navbar-tekis.navbar li.dropdown ul.dropdown-menu > li > a > div + div {
    text-align: right;
    position: relative;
    height: 0;
    top: -19px;
    right: -27px;
}

.navbar-tekis.navbar-default .navbar-nav > .open > a {
    background-color: #8bcfdd;
}

.navbar-tekis.navbar .unsyncedchanges {
    background-color: #ca2030;
}
.navbar-tekis.navbar .offline {
    background-color: #333;
}
/*.navbar-tekis.navbar li.unsyncedchanges a span + span + span:after,*/
.navbar-tekis.navbar li.workingoffline a span + span + span:after {
    content: "*";
}

@media screen and (max-width: 767px) {
    .navbar-tekis .navbar-nav {
        margin: 0 auto;
        float: left;
    }

    .navbar-tekis .navbar-nav li a {
        padding: 15px;
    }

    .navbar-tekis.navbar-default .navbar-nav > li {
        float: left;
        margin: 0;
    }

    .navbar-tekis .navbar-nav .open .dropdown-menu {
        position: absolute;
        border: 1px solid rgba(0,0,0,.15);
        box-shadow: 0 6px 12px rgba(0,0,0,.175);
    }

    .navbar-tekis .navbar-right {
        float: right;
        margin-right: -15px;
    }
    .navbar-tekis .navbar-right .dropdown-menu {
        right: 0;
        left: auto;
    }

    .navbar-tekis .navbar-nav li.dropdown.open ul.dropdown-menu,
    .navbar-default .navbar-nav .open .dropdown-menu > li > a {
        color: #333;
        background-color: white;
    }
    
    .navbar-tekis .navbar-nav li.dropdown.open ul li a:hover,    
    .navbar-tekis .navbar-nav li.dropdown.open ul li.active a:hover {
        background-color: #f5f5f5;
    }
}

/* Other */

.form-group {
    margin-bottom: 7px;
}

.form-horizontal .control-label,
.control-label {
    text-align: left;
}
/*@media (min-width: 768px) {
    .form-horizontal .control-label:not(:empty):after {
        content: ":";
    }
}*/

.btn-gray,
.bg-gray {
    background-color: #f0f0f0;
}

.btn {
    transition: background-color 0.3s ease;
}

.bg-lessgray {
    background-color: #f5f5f5;
}

.bg-grayer {
    background-color: #ddd;
}

.text-warning-lighter {
    color: #c9a800;
}

.btn.disabled, .btn[disabled] {
    opacity: 0.33;
}

.dropdown-menu {
    min-width: 220px;
}

.validationMessage {
    color: red;
}

.clickable {
    cursor: pointer; 
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    /*-o-user-select: none;*/
    user-select: none;
}

.thumbnail {
    margin-bottom: 0;
    display: inline-block;
}
.thumbnail img {
    background-image: url('../Images/bg_transparent.png');
    background-size: 16px 16px;
}
.thumbnail.thumbnail-sm img {
    background-size: 8px 8px;
}

.progress {
    margin-bottom: 5px;
}

button.btn-toggle:not(.active) > span.glyphicon:before {
  content: "\e157";
}
button.btn-toggle.active > span.glyphicon:before {
  content: "\e067";
}

input[type="text"], input[type="email"], input[type="search"], input[type="password"], textarea {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}

.alpha25 { opacity: 0.25; }
.alpha40 { opacity: 0.4; }
.alpha33 { opacity: 0.33; }
.alpha50 { opacity: 0.5; }
.alpha66 { opacity: 0.66; }
.alpha75 { opacity: 0.75; }

/* Table toggle visibility */

.table > tbody > tr td > span.glyphicon.chevrontoggle:before {
    content: "\e114";
}

.table > tbody > tr.collapsed td > span.glyphicon.chevrontoggle:before,
.table > tbody > tr td.collapsed > span.glyphicon.chevrontoggle:before {
    content: "\e080";
}

.table > tbody .clickable {
    transition: background-color 0.3s ease;
}

.table > tbody .clickable:hover {
    transition: background-color 0.3s ease;
    background-color: #e6e6e6;
    color: #333;
}
.table > tbody .clickable.bg-warning:hover {
    background-color: #f0ecd8;
}
.table > tbody .clickable.bg-success:hover {
    background-color: #d3e3cc;
}

.collapse-inverted.in {
    display: none;
}
.collapse-inverted:not(.in) {
    display: block;
}
tr.collapse-inverted:not(.in) {
    display: table-row;
}
td.collapse-inverted:not(.in) {
    display: table-cell;
}

/* Busy indicators */

.busy-indicator-fullscreen {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10001;
    background-color: rgba(0,0,0,.25);
    text-align: center;
}

.busy-indicator-fullscreen div {
    display: inline-block;
    position: relative;
    margin-top: 15px;
    padding: 32px;
    background-color: white;
    background-image: url('../Images/loader.gif');
    background-position: center center;
    background-repeat: no-repeat;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 5px 13px rgba(0,0,0,.4);
}

.busy-indicator {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1999;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url('../Images/loader.gif');
    height: 32px;
}

.busy-indicator-fullscreen div.busy-indicator-sm {
    background-image: url('../Images/loadertransparent-sm.gif');
    height: 16px;
    padding: 16px;
}

.form-control-feedback.busy-indicator-sm {
    background-image: url('../Images/loadertransparent-sm.gif');
    width: 16px;
    height: 16px;
    margin: 9px 7px;
}

.busy-indicator.busy-indicator-tekis,
.busy-indicator-fullscreen div.busy-indicator-tekis {
    background-image: url('../Images/loadertekis.gif');
}

.busy-indicator-fullscreen div.busy-indicator-tekis-sm {
    background-image: url('../Images/loadertekis-sm.gif');
    height: 16px;
}

.search-busy-indicator {
    position: relative;
    height: 0;
    z-index: 1000;
    text-align: left;
    top: 41px;
    left: 41px;
}

@media screen and (max-width: 767px) {
    .search-busy-indicator {
        top: 58px;
        left: 41px;
    }
}

.search-busy-indicator div {
    margin-top: 1px;
    margin-left: 1px;
    box-shadow: none;
    border: none;
    background-color: transparent;
    padding: 0;    
}

/* Everything square */

.panel,
.panel-heading,
.btn,
.form-control,
.input-group-addon,
.dropdown-menu,
.modal,
.modal-header-tekis,
.modal-content,
.thumbnail,
.chosen-container-multi .chosen-choices,
.chosen-container.chosen-container-single .chosen-single,
.chosen-container .chosen-search input[type=text],
.chosen-container .chosen-drop,
.chosen-container-multi .chosen-choices li.search-choice,
.busy-indicator-fullscreen div,
.notification .alert {
    border-radius: 0;
}
